import { useEffect, useState } from "react";

const useFetch = (url) => {
    const [response, setResponse] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    
    useEffect(() => {
        setIsLoading(true);
        setError(null);

        fetch(url, { headers: { "Authorization": "Bearer "+localStorage.getItem("token") }})
        .then(response => {
            if (!response.ok) {
                throw Error('Failed to load data');
            }

            return response.json();
        })
        .then(data => {
            setResponse(data);
            setIsLoading(false);
        })
        .catch(error => {
            setError(error.message);
            setIsLoading(false);
        })
    }, [url]);

    return {response, isLoading, error};
}
 
export default useFetch;