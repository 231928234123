import { library } from '@fortawesome/fontawesome-svg-core';
import { 
    faAngleDown,
    faBars,
    faLaughWink,
    faSignOutAlt,
    faXmark,
    faPlus,
    faEdit,
    faTrash,
    faSpinner,
} from '@fortawesome/free-solid-svg-icons';

library.add(
    faBars,
    faLaughWink,
    faAngleDown,
    faSignOutAlt,
    faXmark,
    faPlus,
    faEdit,
    faTrash,
    faSpinner,
);